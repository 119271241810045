// own class

.img-responsive{
  max-width: 100%
}

@media screen and (min-width: 577px) {
.visible-sm { display : none ; }
}

.bg-circle{
  background-image: url(../img/bg.png);
  background-attachment: fixed;
}
