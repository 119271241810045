// Styling for the navbar
#mainNav {
  border-bottom: 1px solid fade-out($gray-900, .9);
  background-color: $white;
  @include sans-serif-font;
  @include transition-all;
  .navbar-brand {
    font-weight: 700;
    text-transform: uppercase;
    color: $primary;
    @include sans-serif-font;
    &:focus,
    &:hover {
      color: darken($primary, .1);
    }
  }
  .navbar-nav {
    > li.nav-item {
       > a.nav-link,
      > a.nav-link:focus {
        font-size: .9rem;
        font-weight: 700;
        text-transform: uppercase;
        color: $gray-900;
        &:hover {
          color: $primary;
        }
        &.active {
          color: $primary !important;
          background-color: transparent;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
  @media (min-width: 992px) {
    border-color: transparent;
    background-color: transparent;
    .navbar-brand {
      color: fade-out($white, .3);
      &:focus,
      &:hover {
        color: $white;
      }
    }
    .navbar-nav > li.nav-item > a.nav-link {
      padding: 0.5rem 1rem;
    }
    .navbar-nav > li.nav-item > a.nav-link,
    .navbar-nav > li.nav-item > a.nav-link:focus {
      color: fade-out($white, .3);
      &:hover {
        color: $white;
      }
    }
    &.navbar-shrink {
      border-bottom: 1px solid fade-out($gray-900, .9);
      background-color: $white;
      .navbar-brand {
        color: $primary;
        &:focus,
        &:hover {
          color: darken($primary, .1);
        }
      }
      .navbar-nav > li.nav-item > a.nav-link,
      .navbar-nav > li.nav-item > a.nav-link:focus {
        color: $gray-900;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}
